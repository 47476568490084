<template>
  <Dialog>
    <Form
      id="edit-profile-form"
      submit-label="user.account.profile.editDialog.submit"
      :is-loading="isLoading"
      @submit="onSubmit"
      @cancel="dialogStore.closeDialog()"
    >
      <template #errors>
        <div
          v-if="errors.length && errors.includes(ErrorIdent.UNKNOWN_ERROR)"
          class="mb-md"
        >
          <UnknownError />
        </div>
      </template>
      <template #default>
        <TextInput
          name="customerNumber"
          label="user.account.profile.editDialog.customerNumberLabel"
          disabled
          :model-value="data.customerNumber"
          :maxlength="20"
        />
        <Dropdown
          id="reditCustomerType"
          :value="customerTypeOptions[0].value"
          :options="customerTypeOptions"
          name="customerType"
          label="registration.customerTypeLabel"
          disabled
          force-info
          info-on-click
        >
          <template #info>
            <p>
              <Trans keypath="user.account.info.changeCustomerType">
                <span
                  class="font-bold text-white cursor-pointer"
                  @click="redirectTo('/kontakt')"
                >
                  {{ t('contactform') }}
                </span>
              </Trans>
            </p>
          </template>
        </Dropdown>
        <TextInput
          name="title"
          label="user.account.profile.editDialog.titleLabel"
          :model-value="data.title"
          :required="false"
        />
        <TextInput
          name="firstName"
          label="user.account.profile.editDialog.firstNameLabel"
          :model-value="data.firstName"
          required-message="user.account.profile.editDialog.emptyError.firstname"
        />
        <TextInput
          name="lastName"
          label="user.account.profile.editDialog.lastNameLabel"
          :model-value="data.lastName"
          required-message="user.account.profile.editDialog.emptyError.lastname"
        />
        <TextInput
          name="billingEmail"
          label="user.account.profile.editDialog.emailLabel"
          :model-value="data.billingEmail"
          required-message="user.account.profile.editDialog.emptyError.email"
          :maxlength="80"
        />
        <PhoneCanonical
          :country-code="$languageCode.toUpperCase() as CountryCode"
          :required="false"
          :model-value="data.phoneNumber"
        />
      </template>
    </Form>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import { useDialogStore } from '@/stores/useDialogStore';
import { ErrorIdent } from '@/@types/errorIdents';
import Form from '@/components/formFields/localDialogForm/localDialogForm.vue';
import UnknownError from '~/components/formFields/errors/unknown/unknownError.vue';
import { Dropdown, TextInput, PhoneCanonical } from '@/complib';
import type { CountryCode } from '@/components/formFields/types';
import { useEditProfile } from '../useEditProfile';
import Trans from '@/components/Trans.vue';
const { t } = useTrans();

const { onSubmit, errors, customerTypeOptions, data, isLoading } =
  await useEditProfile();

const dialogStore = useDialogStore();

function redirectTo(path: string) {
  window.open(path, '_blank');
}
</script>
